import type { FunctionComponent } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
  SNACKBAR_TYPES,
  Snackbar,
  Typography,
  Button,
  NewIcon,
} from "@lysaab/ui-2";
import {
  ActivePensionMoveInResponse,
  waitingForInsuredStatuses,
  dataLifePensionMove,
  PensionMoveSigningOptions,
  PensionMoveStatus,
} from "../../../data/dataLifePensionMove";
import { PendingCard } from "./PendingCard";
import { useHistory, useLocation } from "react-router";
import { Modal } from "../../../components/modal/Modal";
import { AccountType } from "../../../data/dataAccounts";
import { MoveCard } from "../../../countries/sweden/pages/transferPensions/components/moveCard/MoveCard";
import { MoveSteps } from "../../../countries/sweden/pages/transferPensions/components/moveSteps/MoveSteps";
import { DigitalSigningInstructions } from "../../../countries/sweden/pages/transferPensions/components/digitalSigningInstructions/DigitalSigningInstructions";

import "./PendingPensions.scss";

const messages = defineMessages({
  rejected: {
    id: "sweden.pending-pension.move-status.rejected",
  },
  waitingInsured: {
    id: "sweden.pending-pension.move-status.waiting-insured",
  },
  waitingEmployer: {
    id: "sweden.pending-pension.move-status.waiting-employer",
  },
  waitingInstitute: {
    id: "sweden.pending-pension.move-status.waiting-institute",
  },
  approvedInstitute: {
    id: "sweden.pending-pension.move-status.approved-institute",
  },
  done: {
    id: "sweden.pending-pension.move-status.done",
  },
});

interface MoveDownloadLinkProps {
  move: ActivePensionMoveInResponse;
}

const MoveDownloadLink: FunctionComponent<MoveDownloadLinkProps> = ({
  move,
}) => {
  return (
    <Button
      className="MoveDownloadLink"
      component="a"
      type="button"
      download
      variant="secondary"
      icon="Deposit"
      target="_blank"
      block
      label={
        <FormattedMessage id="sweden.pending-pensions.modal.manual-signing.download.button" />
      }
      href={dataLifePensionMove.getPensionMoveApplicationUrl(move.id, "move")}
    />
  );
};

interface SendHomeButtonProps {
  move: ActivePensionMoveInResponse;
  onSentHome: () => void;
}

const SendHomeButton: FunctionComponent<SendHomeButtonProps> = ({
  move,
  onSentHome,
}) => {
  const intl = useIntl();
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const setSendHome = useCallback(() => {
    setError(undefined);
    setIsLoading(true);
    dataLifePensionMove
      .setDeliveryMethodForMove(move.id, "MAIL")
      .then(() => {
        setIsLoading(false);
        onSentHome();
      })
      .catch(() => {
        setError(
          intl.formatMessage({
            id: "sweden.pending-pensions.modal.manual-signing.send-home.error",
          })
        );
        setIsLoading(false);
      });
  }, [intl, move.id, onSentHome]);

  return (
    <>
      <Button
        className="SendHomeButton"
        variant="secondary"
        icon="Message"
        disabled={isLoading}
        block
        label={
          <FormattedMessage id="sweden.pending-pensions.modal.manual-signing.send-home.button" />
        }
        onClick={setSendHome}
      />
      {error && <Snackbar type={SNACKBAR_TYPES.ERROR}>{error}</Snackbar>}
    </>
  );
};

interface ManualSigningProps {
  move: ActivePensionMoveInResponse;
  onSentHome: () => void;
}

const ManualSigning: FunctionComponent<ManualSigningProps> = ({
  move,
  onSentHome,
}) => {
  return (
    <div className="ManualSigning">
      <Typography type="label" component="h3">
        <FormattedMessage id="sweden.pending-pensions.modal.manual-signing.header" />
      </Typography>

      {move.state === "WAITING_SEND_INSURED" ? (
        <>
          <Typography type="body">
            <FormattedMessage id="sweden.pending-pensions.modal.manual-signing.sending" />
          </Typography>
          <MoveDownloadLink move={move} />
        </>
      ) : (
        <>
          <Typography type="body">
            <FormattedMessage id="sweden.pending-pensions.modal.manual-signing.text" />
          </Typography>
          <SendHomeButton move={move} onSentHome={onSentHome} />
          <MoveDownloadLink move={move} />
        </>
      )}
    </div>
  );
};

function searchParamsWithMoveId(moveId: string) {
  const newSearch = new URLSearchParams(window.location.search);
  newSearch.set("moveId", moveId);
  return `?${newSearch.toString()}`;
}

function searchParamsWithoutMoveId() {
  const newSearch = new URLSearchParams(window.location.search);
  newSearch.delete("moveId");
  // Reset signedMoveId if the modal was opened from a scrive redirect.
  newSearch.delete("signedMoveId");
  return `?${newSearch.toString()}`;
}

function useUrlQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function useActiveMoveId() {
  const urlQuery = useUrlQuery();
  return urlQuery.get("signedMoveId") || urlQuery.get("moveId");
}

function useJustSignedMoveId() {
  const urlQuery = useUrlQuery();
  return urlQuery.get("signedMoveId");
}

function useModalState() {
  const history = useHistory();
  const openModal = useCallback(
    (move: ActivePensionMoveInResponse) => {
      history.replace({
        search: searchParamsWithMoveId(move.id),
      });
    },
    [history]
  );
  const closeModal = useCallback(() => {
    history.replace({
      search: searchParamsWithoutMoveId(),
    });
  }, [history]);

  const activeMoveId = useActiveMoveId();
  const [modalOpened, setModalOpened] = useState(Boolean(activeMoveId));

  useEffect(() => {
    if (activeMoveId) {
      setModalOpened(true);
    }
  }, [activeMoveId]);

  // Delay setting query params until modal has animated out.
  const setActiveMoveDelayed = useCallback(() => {
    setModalOpened(false);
    setTimeout(() => {
      closeModal();
    }, 250);
  }, [closeModal]);

  return { modalOpened, openModal, closeModal: setActiveMoveDelayed };
}

export const PendingPensions: FunctionComponent = () => {
  const intl = useIntl();
  const [moves, setMoves] = useState<ActivePensionMoveInResponse[]>([]);
  const activeMoveId = useActiveMoveId();
  const justSignedMoveId = useJustSignedMoveId();
  const { modalOpened, openModal, closeModal } = useModalState();

  const fetchMoves = useCallback(() => {
    dataLifePensionMove.getActiveMoves().then((response) => {
      setMoves(response);
    });
  }, []);

  useEffect(() => {
    fetchMoves();
  }, [fetchMoves, activeMoveId]);

  let activeMove;
  if (moves.length) {
    activeMove = moves.find((move) => move.id === activeMoveId);
  }

  if (moves.length === 0) {
    return null;
  }

  const sortedMoves = moves.sort((a, b) => {
    const aIsWaiting = waitingForInsuredStatuses.includes(a.state);
    const bIsWaiting = waitingForInsuredStatuses.includes(b.state);

    if (aIsWaiting && !bIsWaiting) {
      return -1; // a comes first
    }
    if (bIsWaiting && !aIsWaiting) {
      return 1; // b comes first
    }
    if (a.amount && b.amount) {
      // sort by amount if both have an amount
      if (aIsWaiting && bIsWaiting) {
        return b.amount - a.amount; // both are waiting, sort by amount descending
      }
    }
    return 0; // no change in order if both are equal or neither matches
  });

  return (
    <div className="overview-pending">
      <Typography type="h2">
        <FormattedMessage id="sweden.pending-pensions.header" />
      </Typography>
      <div className="list">
        {sortedMoves.map((move) => {
          const title =
            move.type === AccountType.LYSA_PPF
              ? intl.formatMessage({
                  id: "sweden.pending-pensions.private-insurance",
                })
              : intl.formatMessage(
                  {
                    id: "sweden.pending-pensions.pension-from",
                  },
                  {
                    employer:
                      move.employer ??
                      intl.formatMessage({
                        id: "sweden.pending-pensions.pension-from.unknown",
                      }),
                  }
                );
          return (
            <PendingCard
              key={move.id}
              onClick={() => {
                openModal(move);
              }}
              title={title}
              icon={getPensionMoveStatusIcon(move.state)}
              byline={intl.formatMessage(getPensionMoveStatusText(move.state))}
              amount={move.amount}
              needAction={
                getPensionMoveStatusText(move.state) === messages.waitingInsured
              }
            />
          );
        })}
      </div>
      <Modal
        header={intl.formatMessage({
          id: "sweden.pending-pensions.modal.header",
        })}
        showModal={modalOpened}
        onClose={() => {
          closeModal();
        }}
        closeOnOverlayClick
      >
        {activeMove && activeMove.id === activeMoveId && (
          <div>
            <div className="PendingPensions__moves">
              <MoveCard
                insuranceCompany={activeMove.institute}
                insuranceNumber={activeMove.insuranceNumber}
                insuranceHolderName={activeMove.employer}
                moveAccountType={activeMove.type}
              />
            </div>

            {waitingForInsuredStatuses.includes(activeMove.state) &&
              activeMove.signing === PensionMoveSigningOptions.MANUAL && (
                <ManualSigning move={activeMove} onSentHome={fetchMoves} />
              )}

            {waitingForInsuredStatuses.includes(activeMove.state) &&
              activeMove.signing === PensionMoveSigningOptions.SCRIVE &&
              activeMove.id !== justSignedMoveId && (
                <DigitalSigningInstructions move={activeMove} />
              )}

            <div className="PendingPensions__steps-header">
              <Typography type="label">
                <FormattedMessage id="sweden.pending-pensions.modal.steps.header" />
              </Typography>
            </div>

            <Snackbar type={SNACKBAR_TYPES.INFO} icon textAlign="left">
              <FormattedMessage id="sweden.pending-pensions.modal.steps.notification" />
            </Snackbar>

            <MoveSteps
              pensionMoveStatuses={[activeMove.state]}
              accountTypes={[activeMove.type]}
              justSignedMove={activeMove.id === justSignedMoveId}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

function getPensionMoveStatusIcon(pensionMoveStatus: PensionMoveStatus) {
  switch (pensionMoveStatus) {
    case PensionMoveStatus.REJECTED_CUSTOMER:
    case PensionMoveStatus.REJECTED_EMPLOYER:
    case PensionMoveStatus.REJECTED_INSTITUTE:
    case PensionMoveStatus.REJECTED_LYSA:
      return (
        <NewIcon.Warning
          className="icon"
          primaryColor="var(--lysa-icon-primary)"
        />
      );

    case PensionMoveStatus.CREATED:
    case PensionMoveStatus.WAITING_SEND_INSURED:
    case PensionMoveStatus.SIGNING_INSURED:
      return (
        <NewIcon.Edit
          className="icon"
          primaryColor="var(--lysa-icon-primary)"
        />
      );

    case PensionMoveStatus.WAITING_SEND_EMPLOYER:
    case PensionMoveStatus.WAITING_FOR_EMPLOYER:
      return (
        <NewIcon.Clock className="icon" primaryColor="var(--color-gray-700)" />
      );

    case PensionMoveStatus.WAITING_SIGNING_ADMIN:
    case PensionMoveStatus.WAITING_SEND_INSTITUTE:
    case PensionMoveStatus.WAITING_FOR_INSTITUTE:
    case PensionMoveStatus.ADDITIONAL_INFO_INSTITUTE:
      return (
        <NewIcon.Clock className="icon" primaryColor="var(--color-gray-700)" />
      );

    case PensionMoveStatus.APPROVED_INSTITUTE:
      return (
        <NewIcon.Check className="icon" primaryColor="var(--color-gray-700)" />
      );

    case PensionMoveStatus.DONE:
      return (
        <NewIcon.Check className="icon" primaryColor="var(--color-gray-700)" />
      );
  }
}

function getPensionMoveStatusText(pensionMoveStatus: PensionMoveStatus) {
  switch (pensionMoveStatus) {
    // These are not included in the response. Kept here for reference.
    case PensionMoveStatus.REJECTED_CUSTOMER:
    case PensionMoveStatus.REJECTED_EMPLOYER:
    case PensionMoveStatus.REJECTED_INSTITUTE:
    case PensionMoveStatus.REJECTED_LYSA:
      return messages.rejected;

    case PensionMoveStatus.CREATED:
    case PensionMoveStatus.WAITING_SEND_INSURED:
    case PensionMoveStatus.SIGNING_INSURED:
      return messages.waitingInsured;

    case PensionMoveStatus.WAITING_SEND_EMPLOYER:
    case PensionMoveStatus.WAITING_FOR_EMPLOYER:
      return messages.waitingEmployer;

    case PensionMoveStatus.WAITING_SIGNING_ADMIN:
    case PensionMoveStatus.WAITING_SEND_INSTITUTE:
    case PensionMoveStatus.WAITING_FOR_INSTITUTE:
    case PensionMoveStatus.ADDITIONAL_INFO_INSTITUTE:
      return messages.waitingInstitute;

    case PensionMoveStatus.APPROVED_INSTITUTE:
      return messages.approvedInstitute;

    // This is not included in the response. Kept here for reference.
    case PensionMoveStatus.DONE:
      return messages.done;
  }
}
