import { FunctionComponent, ReactNode } from "react";
import { Card, Icon, Typography } from "@lysaab/ui-2";
import { Amount } from "../../../components/amount/Amount";

import "./PendingCard.scss";

interface Props {
  onClick: () => void;
  title: string;
  icon: ReactNode;
  byline: string;
  amount?: number;
  needAction?: boolean;
}

export const PendingCard: FunctionComponent<Props> = ({
  onClick,
  title,
  icon,
  byline,
  amount,
  needAction,
}) => {
  return (
    <button onClick={onClick} className="card-button">
      <Card className="card">
        <div className="start">
          {needAction && <div className="dot" />}
          <div className="label">
            <Typography type="body" component="div" className="ellipsis">
              {title}
            </Typography>
            <span className="byline">
              {icon}
              <Typography
                type="body"
                variant="secondary"
                component="div"
                className="ellipsis"
              >
                {byline}
              </Typography>
            </span>
          </div>
        </div>
        <div className="end">
          {typeof amount !== "undefined" && <Amount amount={amount} />}
          <span className="chevron-right">
            <Icon.ChevronRight />
          </span>
        </div>
      </Card>
    </button>
  );
};
