import React, { FunctionComponent } from "react";
import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { TranslatedText } from "../TranslatedText";

export const EmergingMarketsToSavingsAccountSnackbar: FunctionComponent =
  () => {
    return (
      <Snackbar type={SNACKBAR_TYPES.INFO} icon>
        <div>
          <TranslatedText id="closedEmergingMarkets.savingsAccountSnackbar.info" />
        </div>
      </Snackbar>
    );
  };
