import { useQuery } from "@tanstack/react-query";
import { FunctionComponent } from "react";
import { dataAutogiro } from "../../../data/dataAutogiro";
import { useIsPerson } from "../../../hooks/useIsPerson";
import { DocumentLink } from "../DocumentLink";

export const AutogiroDocumentLink: FunctionComponent = () => {
  const {
    data: consents,
    isPending,
    isError,
  } = useQuery({
    queryKey: ["autogiroConsent"],
    queryFn: () => dataAutogiro.getConsents(),
    staleTime: 0,
  });
  const isPerson = useIsPerson();

  if (isPending || isError || consents.length === 0) {
    return null;
  }

  return (
    <DocumentLink
      group="customer"
      document={
        isPerson
          ? "legal/se/sv/villkor-för-insättning-via-autogiro.md"
          : "legal/se/sv/villkor-för-insättning-och-betalning-via-autogiro.md"
      }
    />
  );
};
