import { useEffect } from "react";
import { Spinner } from "@lysaab/ui-2";
import { startImpersonationSession } from "../../hooks/useImpersonation";
import { useHistory } from "react-router";

export const IMPERSONATION_PAGE_URL = "/impersonation";

/**
 * Start a new impersonation session then redirect to the home page.
 * This is all synchronous, but lets us keep track that this is a completely new impersonation session,
 * not just a reload.
 * If we only check the usercontext for impersonation we cannot know wether this is a new
 * session or just a reload.
 */
export function ImpersonationPage() {
  const history = useHistory();

  useEffect(() => {
    startImpersonationSession();
    history.push("/");
  }, [history]);

  return (
    <div className="impersonation-page">
      <Spinner />
    </div>
  );
}
