import { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";

/**
 * When the tab gains focus, ensure our local impersonation session id
 * matches the browser-wide one.
 * If they do not match it means that a new session has been started in another tab.
 * In that case, synchronize the session id and reload the page to present the new session.
 */
function onVisibilityChange() {
  // If tab was just made visible, check if the impersonation session id has changed.
  if (document.visibilityState === "visible") {
    const localImpersonationNonce = sessionStorage.getItem(
      "impersonationSessionId"
    );
    const wideImpersonationNonce = localStorage.getItem(
      "impersonationSessionId"
    );
    // If there is an active impersonation session (one is stored in localStoraget), and the session id does not match then stored for this tab.
    if (
      wideImpersonationNonce &&
      localImpersonationNonce !== wideImpersonationNonce
    ) {
      // Then acknowledge the new session id and reload the page to present the new session.
      sessionStorage.setItem("impersonationSessionId", wideImpersonationNonce);
      document.location.reload();
    }
  }
}

/**
 * Store new impersonation identifier both locally in the tab and browser-wide.
 */
export function startImpersonationSession() {
  // Millisecond timestamp whould be unique enough for this case.
  const sessionId = Date.now().toString();
  sessionStorage.setItem("impersonationSessionId", sessionId);
  localStorage.setItem("impersonationSessionId", sessionId);
}

/**
 * Clear the impersonation identifier from both local and browser-wide storage.
 */
export function clearImpersonationSession() {
  sessionStorage.removeItem("impersonationSessionId");
  localStorage.removeItem("impersonationSessionId");
}

/**
 * Ensure that the impersonation session is consistent across tabs.
 * If a new session has been started in another tab, this hook synchronizes the session id and reloads the page to present the new session.
 */
export function useEnsureImpersonationConsistency() {
  const userContext = useContext(UserContext);

  useEffect(() => {
    // Only setup tab synchronization listener if we are impersonating.
    if (userContext.state?.impersonator) {
      window.addEventListener("visibilitychange", onVisibilityChange);
    }
    // Always clear the impersonation session when the component is unmounted to be sure we are not leaving any dangling listeners.
    return () => {
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [userContext.state?.impersonator]);
}
