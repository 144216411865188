import { Typography } from "@lysaab/ui-2";
import { PendingCard } from "./PendingCard";
import { useAccounts } from "../../../hooks/useAccounts";
import { InvestmentAccount } from "../../../data/dataAccounts";
import { FunctionComponent, useEffect, useState } from "react";
import { getDynamicLink } from "../../../hooks/useCountryUrls";
import { InvestmentAccountId } from "../../../data/dataAccounts";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { dataLifePensionMove } from "../../../data/dataLifePensionMove";
import { ReactComponent as MonthlyDepositsIcon } from "./monthly-deposits.svg";
import { WITHDRAWAL_PENSIONS_URL } from "../../../countries/sweden/pages/withdrawalPensions/WithdrawalPensionsStory";

import "./PendingPensionWithdrawals.scss";

const messages = defineMessages({
  byline: {
    id: "sweden.pending-pension-withdrawal.byline",
    defaultMessage: "Förbered utbetalning",
  },
});

export const PendingPensionWithdrawals: FunctionComponent = () => {
  const intl = useIntl();
  const [needUpdateAccountIds, setNeedUpdateAccountIds] = useState<
    InvestmentAccountId[]
  >([]);
  const { accounts } = useAccounts();
  const safeNavigation = useSafeNavigation();

  useEffect(() => {
    dataLifePensionMove
      .getPensionWithdrawalPlanStatus()
      .then((data) => {
        const { needUpdate } = data;
        setNeedUpdateAccountIds(needUpdate);
      })
      .catch((error) => {
        /**
         * Swallow the error. If not it will bubble up and block user. The component is not essential.
         *
         * TODO: Log the error
         */
        console.log(
          `Could not load pension withdrawal plan status. Error: ${error}`
        );
      });
  }, []);

  const needUpdate = needUpdateAccountIds.reduce((acc, accountId) => {
    if (accounts) {
      const account = accounts.investmentAccounts.find(
        (account) => account.accountId === accountId
      );
      if (account) {
        acc.push(account);
      }
    }
    return acc;
  }, [] as InvestmentAccount[]);

  if (needUpdate.length === 0) {
    return null;
  }

  return (
    <div className="pending-pension-withdrawal">
      <Typography type="h2">
        <FormattedMessage
          id="sweden.pending-pension-withdrawal.header"
          defaultMessage="Skapa utbetalningsplan"
        />
      </Typography>
      <ul className="list">
        {needUpdate.map((pension) => {
          return (
            <li key={pension.accountId} className="list-item">
              <PendingCard
                onClick={() => {
                  safeNavigation(
                    getDynamicLink(WITHDRAWAL_PENSIONS_URL, {
                      accountId: pension.accountId,
                    })
                  );
                }}
                title={pension.name}
                icon={<MonthlyDepositsIcon className="icon" />}
                byline={intl.formatMessage(messages.byline)}
                amount={pension.worth}
                needAction={true}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
