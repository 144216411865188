import React, { ReactNode } from "react";

import { defineMessages, useIntl } from "react-intl";
import { useStoryValues } from "../../../../hooks/useStoryValues";
import { useSafeNavigation } from "../../../../hooks/useSafeNavigation";
import {
  Redirect,
  Route,
  generatePath,
  useHistory,
  useLocation,
} from "react-router";
import { PageStripped } from "../../../../pages/PageStripped";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../../pages/overview/OverviewPage";
import { SNACKBAR_TYPES, Story, useFlash } from "@lysaab/ui-2";
import { Switch } from "../../../../components/route/Switch";
import { memo, FunctionComponent, useEffect } from "react";
import { Sustainability } from "./sustainability/Sustainability";
import { Preference } from "./sustainabilityPreference/SuitabilityPreference";
import {
  DEFAULT_PAYOUT_AGE,
  Move,
  PAYOUT_PLAN_THRESHOLD_AGE,
  TransferContextProvider,
  useTransfer,
} from "./TransferContext";
import {
  GetPensionSuitabilityAssessmentRequest,
  SustainabilityImportance,
  dataInvestments,
  getPensionAccountQuestions,
  isSustainabilityImportantSpecific,
} from "../../../../data/dataInvestments";
import { SustainabilityImportantQuestions } from "./sustainabilityImportantQuestions/SustainabilityImportantQuestions";
import { useLanguage } from "../../../../context/LocalizationContext";
import { ConfirmEsgUpdateWrapper } from "./confirmEsgUpdateWrapper/ConfirmEsgUpdateWrapper";
import { Intro } from "./intro/Intro";
import { InsuranceInformationSummary } from "./insuranceInformationSummary/InsuranceInformationSummary";
import { Advice } from "./advice/Advice";
import { Confirm } from "./confirm/Confirm";
import { Done } from "./done/Done";
import { GroupIntroInsuranceInfo } from "./groupIntroInsuranceInfo/GroupIntroInsuranceInfo";
import { GroupIntroFindPensions } from "./groupIntroFindPensions/GroupIntroFindPensions";
import { Sign } from "./sign/Sign";
import { SnailMail } from "./snailMail/SnailMail";
import { Fees } from "./fees/Fees";
import { BankID } from "./bankID/BankID";
import {
  dataLifePensionMove,
  PensionMoveSigningOptions,
} from "../../../../data/dataLifePensionMove";
import { useUser } from "../../../../context/UserContext";
import { getUserAge } from "./utils/userAge";
import { PayoutPlan } from "./payoutPlan/PayoutPlan";
import { CollectionMethods } from "./collectionMethods/CollectionMethods";
import { InsuranceSigningList } from "./insuranceCompanySigningList/InsuranceSigningList";
import { Footer } from "./components/footer/Footer";
import { getRandomUUID } from "../../../../utils/getRandomUUID";
import { AdvancedInformation } from "./advancedInformation/AdvancedInformation";
import { AgreementAndTerms } from "./agreementAndTerms/AgreementAndTerms";
import { NotMovable } from "./notMovable/NotMovable";
import { DigitalSigning } from "./digitalSigning/DigitalSigning";
import { Holdings } from "./holdings/Holdings";
import { PensionSettings } from "./pensionSettings/PensionSettings";
import { TakenRiskDeviation } from "./takenRiskDeviation/TakenRiskDeviation";
import { Repayment } from "./repayment/Repayment";
import { SuitabilityAssessmentInfo } from "./components/footer/SuitabilityAssessmentInfo";
import { WithdrawalPlan } from "./withdrawalPlan/WithdrawalPlan";
import { useQuery } from "@tanstack/react-query";

export const TRANSFER_PENSIONS_URL = "/transfer-pensions";

const messages = defineMessages({
  header: { id: "sweden.transfer-pensions.header" },
  ariaProgressLabel: {
    id: "sweden.transfer-pensions.ariaProgressLabel",
  },
});

const BASE_ROUTES = {
  INTRO: `${TRANSFER_PENSIONS_URL}/`,
  GROUP_INTRO_FIND: `${TRANSFER_PENSIONS_URL}/group-intro-find`,
  COLLECTION_METHODS: `${TRANSFER_PENSIONS_URL}/collection-methods`,
  INSURANCE_SIGNING_LIST: `${TRANSFER_PENSIONS_URL}/insurance-signing-list`,
  INSURANCE_INFORMATION_SUMMARY: `${TRANSFER_PENSIONS_URL}/insurance-information-summary`,
  NOT_MOVABLE: `${TRANSFER_PENSIONS_URL}/not-movable`,
  GROUP_INTRO_INSURANCE: `${TRANSFER_PENSIONS_URL}/group-intro-insurance`,
  SUSTAINABILITY: `${TRANSFER_PENSIONS_URL}/sustainability`,
  PREFERENCE: `${TRANSFER_PENSIONS_URL}/preference`,
  SUSTAINABILITY_QUESTIONS: `${TRANSFER_PENSIONS_URL}/sustainability-questions`,
  CONFIRM_ESG_UPDATE: `${TRANSFER_PENSIONS_URL}/confirm-esg-update`,
  PAYOUT_PLAN: `${TRANSFER_PENSIONS_URL}/payout-plan`,
  ADVICE: `${TRANSFER_PENSIONS_URL}/advice`,
  FEES: `${TRANSFER_PENSIONS_URL}/fees`,
  HOLDINGS: `${TRANSFER_PENSIONS_URL}/holdings`,
  PENSION_SETTINGS: `${TRANSFER_PENSIONS_URL}/pension-settings`,
  PENSION_SETTINGS_TAKEN_RISK_DEVIATION: `${TRANSFER_PENSIONS_URL}/pension-settings/taken-risk-deviation`,
  PENSION_SETTINGS_WITHDRAWAL_PLAN: `${TRANSFER_PENSIONS_URL}/pension-settings/withdrawal-plan`,
  PENSION_SETTINGS_REPAYMENT: `${TRANSFER_PENSIONS_URL}/pension-settings/repayment`,
  PENSION_SETTINGS_SUSTAINABILITY: `${TRANSFER_PENSIONS_URL}/pension-settings/sustainability`,
  PENSION_SETTINGS_PREFERENCE: `${TRANSFER_PENSIONS_URL}/pension-settings/preference`,
  PENSION_SETTINGS_SUSTAINABILITY_QUESTIONS: `${TRANSFER_PENSIONS_URL}/pension-settings/sustainability-questions`,
  PENSION_SETTINGS_CONFIRM_ESG_UPDATE: `${TRANSFER_PENSIONS_URL}/pension-settings/confirm-esg-update`,
  CONFIRM: `${TRANSFER_PENSIONS_URL}/confirm`,
  ADVANCED_INFORMATION: `${TRANSFER_PENSIONS_URL}/advanced-information`,
  AGREEMENT_AND_TERMS: `${TRANSFER_PENSIONS_URL}/agreement-and-terms`,
  SIGN: `${TRANSFER_PENSIONS_URL}/sign/:caseId`,
  SIGN_DIGITAL: `${TRANSFER_PENSIONS_URL}/digital-signing/:caseId`,
  SIGN_BANK_ID: `${TRANSFER_PENSIONS_URL}/bank-id/:caseId`,
  SIGN_SNAIL_MAIL: `${TRANSFER_PENSIONS_URL}/snail-mail/:caseId`,
  DONE: `${TRANSFER_PENSIONS_URL}/done/:caseId`,
};

const InnerTransfersPensionsStory: FunctionComponent = React.memo(() => {
  const [transfer, setTransfer] = useTransfer();
  const language = useLanguage();
  const history = useHistory();
  const location = useLocation();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);
  const user = useUser();
  const age = getUserAge(user.tin);

  const onBack = () => {
    history.goBack();
  };

  /**
   * Align withdrawalAge with actual age in case user is older than default payout age.
   */
  useEffect(() => {
    if (age && age > DEFAULT_PAYOUT_AGE) {
      setTransfer({ withdrawalAge: age });
    }
  }, [age, setTransfer]);

  /**
   * Generate a new caseId if the user navigates via internal links.
   * In such cases, the context is not reinitialized, and the caseId may remain the same as the previous session.
   */
  useEffect(() => {
    setTransfer({ caseId: getRandomUUID() });
  }, [setTransfer]);

  const moveListHasScrive = (moves: Move[]) =>
    moves.some((move) =>
      move.signing?.includes(PensionMoveSigningOptions.SCRIVE)
    );

  const moveListHasBankId = (moves: Move[]) =>
    moves.some((move) =>
      move.signing?.includes(PensionMoveSigningOptions.BANKID)
    );

  const moveListHasSnailMail = (moves: Move[]) =>
    moves.some((move) =>
      move.signing?.includes(PensionMoveSigningOptions.MANUAL)
    );

  const hideClose = [13, 14, 15, 16, 17, 18, 19];

  // REMEMBER TO CHANGE CONFIRMPAGEINDEX IF YOU SWITCH ORDER OF PAGES
  // THIS IS TO EJECT PEOPLE FROM THE STORY IF THEY TRY TO GO BACK AFTER CONFIRMING, UNTIL
  // BACKEND HAS ADDED SUPPORT TO UPSERT THE CASE.
  return (
    <RedirectIfBackingFromConfirm
      confirmPageIndex={23}
      redirectTo={generatePath(ROUTES.SIGN, { caseId: transfer.caseId })}
    >
      <PageStripped>
        <div className="transfer-pension-story">
          <Story
            ariaLabelProgress={() =>
              intl.formatMessage(messages.ariaProgressLabel, {
                current: currentIndex + 1,
                total: storyLength,
              })
            }
            header={intl.formatMessage(messages.header)}
            progress={
              // This works around a bug in the Story component where it renders 0 as a string,
              // we should fix
              storyProgress === 0 ? undefined : storyProgress
            }
            showBack={
              currentIndex > 0 &&
              currentIndex < Object.values(ROUTES).length - 2 &&
              // Hide back button on the sign intro page
              // REMEMBER TO CHANGE THIS INDEX IF YOU SWITCH ORDER OF PAGES
              currentIndex !== 26
            }
            onBack={onBack}
            transitionKey={currentIndex.toString()}
            showClose={hideClose.includes(currentIndex) ? false : true}
            onExit={() => {
              safeNavigation(getNavLink(OVERVIEW_PAGE_URL));
            }}
          >
            <Switch
              location={location}
              {...{
                order: currentIndex,
              }}
            >
              <Route exact path={ROUTES.INTRO}>
                <Intro
                  next={() => safeNavigation(ROUTES.GROUP_INTRO_FIND)}
                  exit={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
                />
                <Footer />
              </Route>
              <Route exact path={ROUTES.GROUP_INTRO_FIND}>
                <GroupIntroFindPensions
                  next={() => safeNavigation(ROUTES.COLLECTION_METHODS)}
                />
                <Footer />
              </Route>
              <Route exact path={ROUTES.COLLECTION_METHODS}>
                <CollectionMethods
                  toInsuranceSigningList={() =>
                    safeNavigation(ROUTES.INSURANCE_SIGNING_LIST)
                  }
                  toGroupIntoInsurance={() =>
                    safeNavigation(ROUTES.GROUP_INTRO_INSURANCE)
                  }
                />
                <Footer />
              </Route>
              <Route exact path={ROUTES.INSURANCE_SIGNING_LIST}>
                <InsuranceSigningList
                  next={() =>
                    safeNavigation(ROUTES.INSURANCE_INFORMATION_SUMMARY)
                  }
                  reset={() => {
                    safeNavigation(ROUTES.COLLECTION_METHODS);
                  }}
                />
                <Footer />
              </Route>
              <Route path={ROUTES.INSURANCE_INFORMATION_SUMMARY}>
                <InsuranceInformationSummary
                  next={() => safeNavigation(ROUTES.GROUP_INTRO_INSURANCE)}
                  toBack={() => {
                    safeNavigation(ROUTES.COLLECTION_METHODS);
                  }}
                  toNotMovable={() => {
                    safeNavigation(ROUTES.NOT_MOVABLE);
                  }}
                />
                <Footer />
              </Route>
              <Route path={ROUTES.NOT_MOVABLE}>
                <NotMovable />
                <Footer />
              </Route>
              <Route path={ROUTES.GROUP_INTRO_INSURANCE}>
                <GroupIntroInsuranceInfo
                  next={() => safeNavigation(ROUTES.SUSTAINABILITY)}
                />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.SUSTAINABILITY}>
                <Sustainability
                  next={() => {
                    if (
                      transfer.sustainability ===
                      SustainabilityImportance.IMPORTANT
                    ) {
                      safeNavigation(ROUTES.PREFERENCE);
                    } else if (age && age >= PAYOUT_PLAN_THRESHOLD_AGE) {
                      safeNavigation(ROUTES.PAYOUT_PLAN);
                    } else {
                      safeNavigation(ROUTES.ADVICE);
                    }
                  }}
                />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.PREFERENCE}>
                <Preference
                  next={() => {
                    if (isSustainabilityImportantSpecific(transfer)) {
                      safeNavigation(ROUTES.SUSTAINABILITY_QUESTIONS);
                    } else {
                      safeNavigation(ROUTES.ADVICE);
                    }
                  }}
                />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.SUSTAINABILITY_QUESTIONS}>
                <SustainabilityImportantQuestions
                  next={() => {
                    const data: Partial<GetPensionSuitabilityAssessmentRequest> =
                      {
                        language: language,
                        age: age,
                        ...getPensionAccountQuestions(transfer),
                      };
                    dataInvestments
                      .getNewPensionAccountSuitability(data)
                      .then((advise) => {
                        if (advise.esgResult.esgBestMatch) {
                          safeNavigation(ROUTES.CONFIRM_ESG_UPDATE);
                        } else {
                          safeNavigation(ROUTES.ADVICE);
                        }
                      });
                  }}
                />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.CONFIRM_ESG_UPDATE}>
                <ConfirmEsgUpdateWrapper
                  next={() => safeNavigation(ROUTES.ADVICE)}
                />
              </Route>
              <Route exact path={ROUTES.PAYOUT_PLAN}>
                <PayoutPlan next={() => safeNavigation(ROUTES.ADVICE)} />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.ADVICE}>
                <Advice
                  next={() => safeNavigation(ROUTES.CONFIRM)}
                  toFees={() => safeNavigation(ROUTES.FEES)}
                  toHoldings={() => safeNavigation(ROUTES.HOLDINGS)}
                  toPensionSettings={() =>
                    safeNavigation(ROUTES.PENSION_SETTINGS)
                  }
                />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.FEES}>
                <Fees />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.HOLDINGS}>
                <Holdings />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.PENSION_SETTINGS}>
                <PensionSettings
                  toTakenRiskDeviation={() =>
                    safeNavigation(ROUTES.PENSION_SETTINGS_TAKEN_RISK_DEVIATION)
                  }
                  toSustainability={() =>
                    safeNavigation(ROUTES.PENSION_SETTINGS_SUSTAINABILITY)
                  }
                  toRepayment={() =>
                    safeNavigation(ROUTES.PENSION_SETTINGS_REPAYMENT)
                  }
                  toAdvice={() => safeNavigation(ROUTES.ADVICE)}
                  toWithdrawalPlan={() =>
                    safeNavigation(ROUTES.PENSION_SETTINGS_WITHDRAWAL_PLAN)
                  }
                />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.PENSION_SETTINGS_TAKEN_RISK_DEVIATION}>
                <TakenRiskDeviation />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.PENSION_SETTINGS_WITHDRAWAL_PLAN}>
                <WithdrawalPlan
                  toRepayment={() =>
                    safeNavigation(ROUTES.PENSION_SETTINGS_REPAYMENT)
                  }
                />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.PENSION_SETTINGS_REPAYMENT}>
                <Repayment />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.PENSION_SETTINGS_SUSTAINABILITY}>
                <Sustainability
                  next={() => {
                    if (
                      transfer.sustainability ===
                      SustainabilityImportance.IMPORTANT
                    ) {
                      safeNavigation(ROUTES.PENSION_SETTINGS_PREFERENCE);
                    } else {
                      safeNavigation(ROUTES.PENSION_SETTINGS);
                    }
                  }}
                />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.PENSION_SETTINGS_PREFERENCE}>
                <Preference
                  next={() => {
                    if (isSustainabilityImportantSpecific(transfer)) {
                      safeNavigation(
                        ROUTES.PENSION_SETTINGS_SUSTAINABILITY_QUESTIONS
                      );
                    } else {
                      safeNavigation(ROUTES.PENSION_SETTINGS);
                    }
                  }}
                />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route
                exact
                path={ROUTES.PENSION_SETTINGS_SUSTAINABILITY_QUESTIONS}
              >
                <SustainabilityImportantQuestions
                  next={() => {
                    const data: Partial<GetPensionSuitabilityAssessmentRequest> =
                      {
                        language: language,
                        age: age,
                        ...getPensionAccountQuestions(transfer),
                      };
                    dataInvestments
                      .getNewPensionAccountSuitability(data)
                      .then((advise) => {
                        if (advise.esgResult.esgBestMatch) {
                          safeNavigation(
                            ROUTES.PENSION_SETTINGS_CONFIRM_ESG_UPDATE
                          );
                        } else {
                          safeNavigation(ROUTES.PENSION_SETTINGS);
                        }
                      });
                  }}
                />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.PENSION_SETTINGS_CONFIRM_ESG_UPDATE}>
                <ConfirmEsgUpdateWrapper
                  next={() => {
                    safeNavigation(ROUTES.PENSION_SETTINGS);
                  }}
                />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.CONFIRM}>
                <Confirm
                  next={(caseId) => {
                    safeNavigation(generatePath(ROUTES.SIGN, { caseId }));
                  }}
                  toAdvancedInformation={() =>
                    safeNavigation(ROUTES.ADVANCED_INFORMATION)
                  }
                  toAgreementAndTerms={() =>
                    safeNavigation(ROUTES.AGREEMENT_AND_TERMS)
                  }
                />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.ADVANCED_INFORMATION}>
                <AdvancedInformation />
                <Footer>
                  <SuitabilityAssessmentInfo />
                </Footer>
              </Route>
              <Route exact path={ROUTES.AGREEMENT_AND_TERMS}>
                <AgreementAndTerms />
                <Footer />
              </Route>
              <Route exact path={ROUTES.SIGN}>
                <Sign
                  next={(caseId) => {
                    if (moveListHasScrive(transfer.moves)) {
                      safeNavigation(
                        generatePath(ROUTES.SIGN_DIGITAL, { caseId })
                      );
                    } else if (moveListHasBankId(transfer.moves)) {
                      safeNavigation(
                        generatePath(ROUTES.SIGN_BANK_ID, { caseId })
                      );
                    } else if (moveListHasSnailMail(transfer.moves)) {
                      safeNavigation(
                        generatePath(ROUTES.SIGN_SNAIL_MAIL, { caseId })
                      );
                    } else {
                      // TODO: Add 404 or something
                    }
                  }}
                />
                <Footer />
              </Route>
              <Route exact path={ROUTES.SIGN_DIGITAL}>
                <DigitalSigning
                  next={(caseId) => {
                    if (moveListHasBankId(transfer.moves)) {
                      safeNavigation(
                        generatePath(ROUTES.SIGN_BANK_ID, { caseId })
                      );
                    } else if (moveListHasSnailMail(transfer.moves)) {
                      safeNavigation(
                        generatePath(ROUTES.SIGN_SNAIL_MAIL, { caseId })
                      );
                    } else {
                      safeNavigation(generatePath(ROUTES.DONE, { caseId }));
                    }
                  }}
                />
                <Footer />
              </Route>
              <Route exact path={ROUTES.SIGN_BANK_ID}>
                <BankID
                  next={(caseId) => {
                    if (moveListHasSnailMail(transfer.moves)) {
                      safeNavigation(
                        generatePath(ROUTES.SIGN_SNAIL_MAIL, { caseId })
                      );
                    } else {
                      safeNavigation(generatePath(ROUTES.DONE, { caseId }));
                    }
                  }}
                />
                <Footer />
              </Route>
              <Route exact path={ROUTES.SIGN_SNAIL_MAIL}>
                <SnailMail
                  next={(caseId) =>
                    safeNavigation(generatePath(ROUTES.DONE, { caseId }))
                  }
                />
                <Footer />
              </Route>
              <Route exact path={ROUTES.DONE}>
                <Done
                  next={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
                />
                <Footer />
              </Route>
              <Route>
                <p>Whoops - page not found</p>
              </Route>
            </Switch>
          </Story>
        </div>
      </PageStripped>
    </RedirectIfBackingFromConfirm>
  );
});

export const TransfersPensionsStory: FunctionComponent = memo(() => {
  return (
    <TransferContextProvider>
      <InnerTransfersPensionsStory />
    </TransferContextProvider>
  );
});

interface EjectBackFromConfirmProps {
  children: ReactNode;
  confirmPageIndex: number;
  redirectTo: string;
}

/**
 * Block user from going back to steps before the confim step after the transfer has been confirmed.
 */
const RedirectIfBackingFromConfirm: FunctionComponent<
  EjectBackFromConfirmProps
> = ({ children, confirmPageIndex, redirectTo }) => {
  const [currentIndex] = useStoryValues(BASE_ROUTES);
  const [transfer] = useTransfer();
  const pushFlash = useFlash();
  const intl = useIntl();

  /**
   * If caseData exists, the user has confirmed the transfer and should be redirected to a safe page. Defined in redirectTo.
   */
  const { data: caseIdExists } = useQuery({
    queryKey: ["caseIdExists", transfer.caseId],
    queryFn: () => dataLifePensionMove.getCaseIdExists(transfer.caseId),
  });

  const shouldRedirect = currentIndex <= confirmPageIndex && caseIdExists;

  /**
   * Delay flash message to avoid state update during render.
   */
  useEffect(() => {
    if (shouldRedirect) {
      pushFlash({
        text: intl.formatMessage({
          id: "sweden.transfer-pension.redirectWhenBackFromConfirm",
        }),
        type: SNACKBAR_TYPES.WARNING,
      });
    }
  }, [intl, pushFlash, shouldRedirect]);

  if (shouldRedirect) {
    return <Redirect to={{ pathname: redirectTo }} />;
  }
  return <>{children}</>;
};
