import React, { FunctionComponent } from "react";
import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { TranslatedText } from "../TranslatedText";
import { useIntl } from "react-intl";

export const EmergingMarketsSnackbar: FunctionComponent = () => {
  const intl = useIntl();
  return (
    <Snackbar type={SNACKBAR_TYPES.INFO} icon>
      <div>
        <TranslatedText
          id="closedEmergingMarkets.snackbar.info"
          values={{ percentage: intl.formatNumber(0.9, { style: "percent" }) }}
        />
      </div>
    </Snackbar>
  );
};
