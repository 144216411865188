import { Institute } from "../../../../../data/dataLifePensionMove";

export const institutePrettyNames: Record<Institute, string> = {
  [Institute.AMF]: "Amf",
  [Institute.ALECTA]: "Alecta",
  [Institute.AVANZA]: "Avanza",
  [Institute.FOLKSAM]: "Folksam",
  [Institute.FUTUR]: "Futur",
  [Institute.HANDELSBANKEN]: "Handelsbanken",
  [Institute.IDUN_LIV]: "Idun Liv",
  [Institute.KPA]: "KPA",
  [Institute.LANSFORSAKRINGAR]: "Länsförsäkringar",
  [Institute.NORDEA]: "Nordea",
  [Institute.NORDNET]: "Nordnet",
  [Institute.MOVESTIC]: "Movestic",
  [Institute.SEB]: "SEB",
  [Institute.SKANDIA]: "Skandia",
  [Institute.SPP]: "SPP",
  [Institute.SVENSKA_FRIBREVSBOLAGET]: "Svenska Fribrevsbolaget",
  [Institute.SWEDBANK]: "Swedbank",
  [Institute.SH_PENSION]: "SH Pension",
};

export const instituteAlternatives = Object.values(Institute)
  // .filter((value) => value !== Institute.SWEDBANK) // Swedbank was only part of test phase
  .map((value) => ({
    text: institutePrettyNames[value],
    value,
  }));
