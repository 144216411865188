import { isInvestmentAccount } from "../data/dataAccounts";

/* ISIN for Lysa Emerging Markets Equity Broad B/E/D */
const LEMEB_B = "SE0023260401";
const LEMEB_E = "SE0023260427";
const LEMEB_D = "SE0023260419";

export function useHasEmergingMarketPositions(account: unknown) {
  return (
    isInvestmentAccount(account) &&
    Object.keys(account.positions).some((position) => {
      return (
        position === LEMEB_B || position === LEMEB_D || position === LEMEB_E
      );
    })
  );
}
