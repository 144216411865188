import cx from "classnames";
import { GenericBankIcon } from "../bankIcon/banks/genericBankIcon/GenericBankIcon";

import "./PensionLogo.scss";

const letters = (company: Pension["key"]): string => {
  switch (company) {
    case "AMF":
      return "AMF";
    case "ALECTA":
      return "ALE";
    case "AVANZA":
      return "AZA";
    case "FOLKSAM":
      return "FOL";
    case "FUTUR":
      return "FUT";
    case "HANDELSBANKEN":
      return "HAN";
    case "IDUN_LIV":
      return "IDU";
    case "KPA":
      return "KPA";
    case "LANSFORSAKRINGAR":
      return "LAN";
    case "MOVESTIC":
      return "MOV";
    case "NORDEA":
      return "NOR";
    case "NORDNET":
      return "NOR";
    case "SEB":
      return "SEB";
    case "SKANDIA":
      return "SKA";
    case "SPP":
      return "SPP";
    case "SVENSKA_FRIBREVSBOLAGET":
      return "SFR";
    case "SWEDBANK":
      return "SWE";
    case "SH_PENSION":
      return "SHP";
    default:
      return company ? company?.substring(0, 3).toUpperCase() : "AAA";
  }
};

export interface Pension {
  key?: string;
  short?: string;
  long?: string;
  nameInKontonummer?: string;
}

interface Props {
  pension: Pension;
  className?: string;
  size?: number;
}

export function PensionLogo({ pension, className, size = 40 }: Props) {
  if (!pension || pension.key === "UNKNOWN") {
    return <GenericBankIcon size={size} className={className} />;
  }

  return (
    <div
      className={cx("pension-logo", pension.key?.toLowerCase(), className)}
      style={{ height: `${size}px`, width: `${size}px` }}
    >
      {letters(pension.key)}
    </div>
  );
}
