import {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { TranslatedText } from "../../components/TranslatedText";
import { LocalizationContext } from "../../context/LocalizationContext";
import { UserContext } from "../../context/UserContext";
import {
  AccountShareByMe,
  AccountShareWithMe,
  dataAttorney,
} from "../../data/dataAttorney";
import {
  dataDocuments,
  DocumentInformation,
  Documents,
  DocumentsActiveAccount,
  DocumentsActiveSavingsAccount,
  DocumentsClosedAccount,
  DocumentsClosedSavingsAccount,
  getLatestDocuments,
} from "../../data/dataDocuments";
import { DocumentLink } from "./DocumentLink";
import { DocumentLinks } from "./DocumentLinks";
import {
  customerDocuments,
  accountDocuments,
  otherDocuments,
  versionedAccountDocuments,
  savingsAccountDocuments,
  sharedAccountDocuments,
  sustainabilityDocuments,
  otherPensionAccounts,
} from "./DocumentLists";
import "./DocumentsMenu.scss";
import { DocumentGroupType } from "./DocumentsPage";
import { AccountType, isPensionAccountType } from "../../data/dataAccounts";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";
import { AUTOGIRO_DEPOSIT_PAGE_URL } from "../../countries/sweden/pages/deposits/Autogiro/deposit/AutogiroDepositPage";
import { AutogiroDocumentLink } from "./components/AutogiroDocumentLink";
import { getNavLink } from "../../hooks/useCountryUrls";

interface Props {
  docData: Documents;
}

export const DocumentsMenu: FunctionComponent<Props> = ({ docData }) => {
  const { legalEntityType } = useContext(UserContext).state;
  const { country } = useContext(LocalizationContext).state;
  const { accounts, customer, savingsAccounts } = docData;
  const [documentsInformation, setDocumentsInformation] =
    useState<DocumentInformation[]>();
  const [accountSharesWithMe, setAccountSharesWithMe] =
    useState<AccountShareWithMe[]>();
  const [accountSharesByMe, setAccountSharesByMe] =
    useState<AccountShareByMe[]>();

  useEffect(() => {
    dataDocuments.getDocumentsInformation().then((information) => {
      setDocumentsInformation(information);
    });
    dataAttorney.getAccountSharesWithMe().then((shares) => {
      setAccountSharesWithMe(shares);
    });
    dataAttorney.getAccountSharesByMe().then((shares) => {
      setAccountSharesByMe(shares);
    });
  }, []);

  if (!country || !legalEntityType || !documentsInformation) {
    return null;
  }

  const hasPensionAccount = accounts.some((account) =>
    isPensionAccountType(account.accountType)
  );

  return (
    <div className="document-page-menu">
      <h2>
        <TranslatedText id="documents.menu.header" />
      </h2>
      <h3>
        <TranslatedText id="documents.menu.customer-header" />
      </h3>
      <ul>
        {customerDocuments[legalEntityType][country].map((documents) => (
          <DocumentLinks
            group="customer"
            documents={documents}
            key={documents[0]}
            accounts={accounts}
            customer={customer}
            documentsInformation={documentsInformation}
          />
        ))}
      </ul>
      <h3>
        <TranslatedText id="documents.menu.account-header" />
      </h3>
      {docData.accounts
        .filter((account): account is DocumentsActiveAccount => {
          return (
            typeof (account as DocumentsClosedAccount).closed === "undefined"
          );
        })
        .map((account) => (
          <Fragment key={account.accountId}>
            <h4>{account.accountName}</h4>
            <ul>
              {getLatestDocuments(
                documentsInformation,
                ...accountDocuments[account.accountType][country]
              ).map((document) => {
                return (
                  <DocumentLink
                    group={account.accountId}
                    key={document}
                    document={document}
                  />
                );
              })}
              {versionedAccountDocuments[account.accountType][country].map(
                (documents) => (
                  <DocumentLinks
                    group={account.accountId}
                    documents={documents}
                    key={documents[0]}
                    accounts={accounts.filter(
                      ({ accountId }) => accountId === account.accountId
                    )}
                    documentsInformation={documentsInformation}
                  />
                )
              )}
              {accountSharesByMe
                ?.filter((share) => share.accountId === account.accountId)
                .map((share) => (
                  <Fragment key={share.accountShareId}>
                    {getLatestDocuments(
                      documentsInformation,
                      ...sharedAccountDocuments[country]
                    ).map((document) => (
                      <DocumentLink
                        group={share.accountShareId}
                        key={document}
                        document={document}
                        documentGroupType={DocumentGroupType.Share}
                        additionalText={`(${share.inviteeName})`}
                      />
                    ))}
                  </Fragment>
                ))}
              {/**
               * TODO: Figure out how to handle this document
               */}
              {account.accountType === AccountType.KF_SWE && (
                <li>
                  <a
                    href="/docs/PRIIP KID Lysa Life - Företagsägd kapitalförsäkring.pdf"
                    download
                  >
                    PRIIP KID Lysa Life - Företagsägd kapitalförsäkring
                  </a>
                </li>
              )}
            </ul>
          </Fragment>
        ))}
      {savingsAccounts
        .filter((account): account is DocumentsActiveSavingsAccount => {
          return (
            typeof (account as DocumentsClosedSavingsAccount).closed ===
            "undefined"
          );
        })
        .map((account) => (
          <Fragment key={account.accountId}>
            <h4>{account.accountName}</h4>
            <ul>
              {getLatestDocuments(
                documentsInformation,
                ...savingsAccountDocuments[country]
              ).map((document) => {
                return (
                  <DocumentLink
                    group={account.accountId}
                    key={document}
                    document={document}
                  />
                );
              })}

              {accountSharesByMe
                ?.filter((share) => share.accountId === account.accountId)
                .map((share) =>
                  getLatestDocuments(
                    documentsInformation,
                    ...sharedAccountDocuments[country]
                  ).map((document) => (
                    <DocumentLink
                      group={share.accountShareId}
                      key={document}
                      document={document}
                      documentGroupType={DocumentGroupType.Share}
                      additionalText={`(${share.inviteeName})`}
                    />
                  ))
                )}
            </ul>
          </Fragment>
        ))}
      {accountSharesWithMe && accountSharesWithMe.length > 0 && (
        <>
          <h3>
            <TranslatedText id="documents.menu.followed-accounts" />
          </h3>
          {accountSharesWithMe.map((share) => (
            <Fragment key={share.accountShareId}>
              <h4>{share.accountName}</h4>
              <ul>
                {getLatestDocuments(
                  documentsInformation,
                  ...sharedAccountDocuments[country]
                ).map((document) => (
                  <DocumentLink
                    group={share.accountShareId}
                    key={document}
                    document={document}
                    documentGroupType={DocumentGroupType.Share}
                    additionalText={`(${share.ownerName})`}
                  />
                ))}
              </ul>
            </Fragment>
          ))}
        </>
      )}
      <h3>
        <TranslatedText id="documents.menu.other-header" />
      </h3>
      <ul>
        {otherDocuments[legalEntityType][country].map((documents) => (
          <DocumentLinks
            group="customer"
            documents={documents}
            key={documents[0]}
            accounts={accounts}
            customer={customer}
            documentsInformation={documentsInformation}
          />
        ))}
        <RouteAwareToggle path={getNavLink(AUTOGIRO_DEPOSIT_PAGE_URL)}>
          <AutogiroDocumentLink />
        </RouteAwareToggle>
        {hasPensionAccount &&
          otherPensionAccounts[country].map((documents) => (
            <DocumentLinks
              group="customer"
              documents={documents}
              key={documents[0]}
              accounts={accounts}
              customer={customer}
              documentsInformation={documentsInformation}
            />
          ))}
      </ul>
      {sustainabilityDocuments[legalEntityType][country].length > 0 && (
        <>
          <h3>
            <TranslatedText id="documents.menu.sustainability" />
          </h3>
          <ul>
            {sustainabilityDocuments[legalEntityType][country].map(
              (documents) => {
                return (
                  <DocumentLinks
                    group="customer"
                    documents={documents}
                    key={documents[0]}
                    accounts={accounts}
                    customer={customer}
                    documentsInformation={documentsInformation}
                  />
                );
              }
            )}
          </ul>
        </>
      )}
    </div>
  );
};
