import { FunctionComponent } from "react";

import { useIntl } from "react-intl";
import { Redirect, useHistory } from "react-router";
import type { GetSuitabilityAssessmentResponse } from "../../../../../data/dataInvestments";
import {
  getPensionAccountQuestions,
  useNewPensionAccountSuitabilityQuery,
} from "../../../../../data/dataInvestments";
import { LysaCountry } from "@lysaab/shared";
import { getUserAge } from "../utils/userAge";
import { TransferContextState, useTransfer } from "../TransferContext";
import {
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { useUser } from "../../../../../context/UserContext";
import { AccountType } from "../../../../../data/dataAccounts";
import { Amount } from "../../../../../components/amount/Amount";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import {
  useLanguage,
  useLocalizationContext,
} from "../../../../../context/LocalizationContext";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { useEstimatedFeesQuery } from "../../../../../data/dataFees";
import { FormattedPercentage } from "../../../../../components/formattedPercentage/FormattedPercentage";

import "./Fees.scss";
import { useEstimatedTaxQuery } from "../../../../../data/dataLife";

const EXAMPLE_AMOUNT = 100_000;

const useNewPensionAccountSuitability = (transfer: TransferContextState) => {
  const language = useLanguage();
  const user = useUser();
  const age = getUserAge(user.tin);

  const newPensionAccountSuitabilityParams =
    typeof transfer.sustainability !== "undefined"
      ? {
          language,
          age,
          ...getPensionAccountQuestions(transfer),
        }
      : undefined;
  return useNewPensionAccountSuitabilityQuery(
    newPensionAccountSuitabilityParams
  );
};

const useEstimatedTax = () => {
  const estimatedTaxParams = {
    oneTimeDeposit: EXAMPLE_AMOUNT,
    monthlyDeposit: 0,
    accountType: AccountType.LYSA_TJP,
  };
  return useEstimatedTaxQuery(estimatedTaxParams);
};

const useEstimatedFees = (
  transfer: TransferContextState,
  advice: GetSuitabilityAssessmentResponse | undefined
) => {
  const accountType = transfer.moves[0]?.type;
  // The check for accountType is mostly to satisfy typescript.
  // In the original <Fees> component we check that we have a move and redirect the use away otherwise.
  const estimatedFeesParams =
    advice && accountType
      ? {
          amount: EXAMPLE_AMOUNT,
          risk: Math.min(advice.advisedRisk + transfer.takenRiskDeviation, 100),
          investmentType: advice.investmentType,
          country: LysaCountry.SWEDEN,
          accountType,
        }
      : undefined;
  return useEstimatedFeesQuery(estimatedFeesParams);
};

export const Fees: FunctionComponent = () => {
  const [transfer] = useTransfer();
  const history = useHistory();

  const localizationContext = useLocalizationContext();
  const intl = useIntl();

  const {
    data: advice,
    isPending: isPendingAdvice,
    isError: isErrorAdvice,
  } = useNewPensionAccountSuitability(transfer);

  const {
    data: estimatedTax,
    isPending: isPendingEstimatedTax,
    isError: isErrorEstimatedTax,
  } = useEstimatedTax();

  const {
    data: estimatedFees,
    isPending: isPendingEstimatedFees,
    isError: isErrorEstimatedFees,
  } = useEstimatedFees(transfer, advice);

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  if (typeof transfer?.moves[0]?.institute === "undefined") {
    return <Redirect to={getNavLink(TRANSFER_PENSIONS_URL)} />;
  }

  if (isErrorAdvice || isErrorEstimatedFees || isErrorEstimatedTax) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <TranslatedText id="sweden.transfer-pension.fees.error" />
      </Snackbar>
    );
  }

  if (isPendingAdvice || isPendingEstimatedFees || isPendingEstimatedTax) {
    return <Spinner />;
  }

  const {
    cost: {
      insurancePremium,
      fundManagement,
      discretionary,
      fundAssets,
      transactionFees,
      total,
    },
  } = estimatedFees;

  // Fees (Kr)
  const insuranceFee =
    EXAMPLE_AMOUNT * (insurancePremium ? insurancePremium / 100 : 0);
  const lysaFee = EXAMPLE_AMOUNT * ((discretionary + fundManagement) / 100);
  const underlyingFee = EXAMPLE_AMOUNT * (fundAssets / 100);
  const transactionFee = EXAMPLE_AMOUNT * (transactionFees / 100);
  const totalFee = EXAMPLE_AMOUNT * (total / 100);

  // Costs (%) (displayed next to label for transparency)
  const insuranceCost = insurancePremium;
  const lysaCost = discretionary + fundManagement;
  const underlyingCost = fundAssets;
  const transactionCosts = transactionFees;
  const totalCost = total;

  if (!insuranceCost) {
    return null;
  }

  return (
    <article className="transfer-pension-fees">
      <section>
        <Typography type="h2">
          <TranslatedText id="sweden.transfer-pension.fees.header" />
        </Typography>
        <Typography type="h3">
          <TranslatedText id="sweden.transfer-pension.fees.sub-title" />
        </Typography>
        <Typography type="body" className="ingress">
          <TranslatedText
            id="sweden.transfer-pension.fees.ingress"
            values={{
              exampleDeposit: intl.formatNumber(EXAMPLE_AMOUNT, {
                style: "currency",
                currency: localizationContext.state.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
            }}
          />
        </Typography>
        <dl>
          <dt>
            <TranslatedText
              id={"sweden.transfer-pension.fees.insurance-fee.label"}
            />
            <FormattedPercentage value={insuranceCost} decimals={3} />
          </dt>
          <dd>
            <Amount amount={insuranceFee} />
          </dd>
        </dl>
        <dl>
          <dt>
            <TranslatedText
              id={"sweden.transfer-pension.fees.lysa-fee.label"}
            />
            <FormattedPercentage value={lysaCost} decimals={3} />
          </dt>
          <dd>
            <Amount amount={lysaFee} />
          </dd>
        </dl>
        <dl>
          <dt>
            <TranslatedText
              id={"sweden.transfer-pension.fees.underlying-fee.label"}
            />
            <FormattedPercentage value={underlyingCost} decimals={3} />
          </dt>
          <dd>
            <Amount amount={underlyingFee} />
          </dd>
        </dl>
        <dl>
          <dt>
            <TranslatedText
              id={"sweden.transfer-pension.fees.transaction-fee.label"}
            />
            <FormattedPercentage value={transactionCosts} decimals={3} />
          </dt>
          <dd>
            <Amount amount={transactionFee} />
          </dd>
        </dl>
        <dl className="total">
          <dt>
            <TranslatedText id={"sweden.transfer-pension.fees.total.label"} />
            <FormattedPercentage value={totalCost} decimals={3} />
          </dt>
          <dd>
            <Amount amount={totalFee} />
          </dd>
        </dl>
        <Typography type="body-small">
          <TranslatedText
            id="sweden.transfer-pension.fees.footnote"
            values={{
              exampleDeposit: intl.formatNumber(EXAMPLE_AMOUNT, {
                style: "currency",
                currency: localizationContext.state.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
            }}
          />
        </Typography>
        <Typography type="label">
          <TranslatedText id="sweden.transfer-pension.fees.tax.header" />
        </Typography>
        <Typography type="body">
          <TranslatedText
            id="sweden.transfer-pension.fees.tax.body"
            values={{
              year: estimatedTax.year,
              taxPercentage: intl.formatNumber(
                estimatedTax.taxPercentage / 100,
                {
                  style: "percent",
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                }
              ),
            }}
          />
        </Typography>
        <Typography type="label">
          <TranslatedText id="sweden.transfer-pension.fees.transfer-fee.header" />
        </Typography>
        <Typography type="body">
          <TranslatedText id="sweden.transfer-pension.fees.transfer-fee.body" />
        </Typography>
      </section>
      <section>
        <Button
          block
          type="button"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.fees.positions.back",
          })}
          onClick={history.goBack}
        />
      </section>
    </article>
  );
};
