import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { defineMessages, useIntl } from "react-intl";
import "./ImpersonationBar.scss";
import {
  Language,
  LocalizationContext,
} from "../../context/LocalizationContext";
import { Select } from "@lysaab/ui-2";
import { useEnsureImpersonationConsistency } from "../../hooks/useImpersonation";

const messages = defineMessages({
  header: {
    id: "impersonation.bar.header",
    description: "Impersonation bar header text",
    defaultMessage: "Impersonating",
  },
});

export function ImpersonationBar() {
  const userContext = useContext(UserContext);
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();
  useEnsureImpersonationConsistency();

  if (!userContext.state || !userContext.state.impersonator) {
    return null;
  }

  const alternatives = Object.entries(Language).map((name) => ({
    text:
      name[0].charAt(0).toLocaleUpperCase() +
      name[0].slice(1).toLocaleLowerCase(),
    value: name[1],
  }));

  return (
    <div className="impersonation-bar">
      <div className="content-wrapper">
        {intl.formatMessage(messages.header)}
        <div className="impersonated-user">{userContext.state.name}</div>
        <Select
          alternatives={alternatives}
          label="Language"
          placeholder="Select language"
          value={alternatives.find(
            (alt) => localizationContext.state.language === alt.value
          )}
          onChange={(newVal) =>
            localizationContext.setState({ language: newVal.value })
          }
        />
      </div>
    </div>
  );
}
