import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  MaxValidator,
  MinValidator,
  MoneyInput,
  Button,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { AnimateHeight } from "../../../components/animateHeight/AnimateHeight";
import { EventTracker } from "../../../components/eventTracker/EventTracker";
import { TransferItem } from "../../../components/internalTransferItem/InternalTransferItem";
import { TranslatedText } from "../../../components/TranslatedText";
import { LocalizationContext } from "../../../context/LocalizationContext";
import {
  CompoundAccount,
  isInvestmentAccount,
  isSavingsAccount,
} from "../../../data/dataAccounts";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
  TrackerEvent,
} from "../../../data/dataCustomerTracking";
import {
  AddTransferAmountArguments,
  AddTransferDrainArguments,
  dataTransfer,
  getMaxTransferableAmount,
  isMergedInternalTransferInvestmentAccount,
  MergedInternalTransferAccount,
  TransferType,
} from "../../../data/dataTransfer";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useIsReadOnly } from "../../../hooks/useIsReadOnly";
import { InternalTransferContext } from "../InternalTransferContext";
import {
  BASE_ROUTES,
  INTERNAL_TRANSFER_PAGE_URL,
} from "../InternalTransferPage";
import { DisclaimerIskToSavings } from "../../withdrawal/internalRequest/components/disclaimer/DisclaimerIskToSavings";

import "./InternalTransferAmount.scss";
import { useHasEmergingMarketPositions } from "../../../hooks/useHasEmergingMarketsPositions";
import { EmergingMarketsToSavingsAccountSnackbar } from "../../../components/emergingMarketsSnackbars/EmergingMarketsToSavingsAccountSnackbar";

const MIN_TRANSFER_PERCENTAGE_ISK_TO_ISK = 0.05;
const MIN_TRANSFER_PERCENTAGE_ISK_TO_SAVINGSACCOUNT = 0.2;

interface Props {
  next: (isDrain: boolean) => void;
}

export function InternalTransferAmount({ next }: Props) {
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const [selectedTransferType, setSelectedTransferType] =
    useState<Alternative<TransferType>>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const localizationContext = useContext(LocalizationContext);
  const internalTransferContext = useContext(InternalTransferContext);
  const history = useHistory();
  const isReadOnly = useIsReadOnly();
  const isIskToSavings =
    isInvestmentAccount(internalTransferContext.state.fromAccount) &&
    isSavingsAccount(internalTransferContext.state.toAccount);
  const hasEmergingMarketPositions = useHasEmergingMarketPositions(
    internalTransferContext.state.fromAccount
  );

  useEffect(() => {
    if (
      internalTransferContext.state.fromAccount &&
      internalTransferContext.state.toAccount
    ) {
      return;
    } else if (history.location.pathname.endsWith(BASE_ROUTES.AMOUNT)) {
      history.push(getNavLink(INTERNAL_TRANSFER_PAGE_URL));
    }
  }, [
    history,
    internalTransferContext.state.fromAccount,
    internalTransferContext.state.toAccount,
  ]);

  const transferAlternatives = [
    {
      value: TransferType.DRAIN,
      text: intl.formatMessage({
        id: "internal-transfer.story.amount.drain",
      }),
    },
    {
      value: TransferType.AMOUNT,
      text: intl.formatMessage({
        id: "internal-transfer.story.amount.amount",
      }),
    },
  ];

  const onSubmit = () => {
    if (
      formRef.current?.isInvalid ||
      !internalTransferContext.state.fromAccount ||
      !internalTransferContext.state.toAccount ||
      !selectedTransferType ||
      isReadOnly
    ) {
      return;
    } else if (
      selectedTransferType.value === TransferType.AMOUNT &&
      typeof internalTransferContext.state.amount === "undefined"
    ) {
      return;
    }
    const amount = internalTransferContext.state.amount;
    let transferParameters:
      | AddTransferDrainArguments
      | AddTransferAmountArguments;
    if (
      selectedTransferType.value === TransferType.AMOUNT &&
      typeof amount !== "undefined"
    ) {
      transferParameters = {
        toAccountId: internalTransferContext.state.toAccount.accountId,
        fromAccountId: internalTransferContext.state.fromAccount.accountId,
        requestId: new Date().getTime(),
        transferType: TransferType.AMOUNT,
        amount: amount,
      };
    } else {
      transferParameters = {
        toAccountId: internalTransferContext.state.toAccount.accountId,
        fromAccountId: internalTransferContext.state.fromAccount.accountId,
        requestId: new Date().getTime(),
        transferType: TransferType.DRAIN,
      } as AddTransferDrainArguments;
    }
    setIsLoading(true);
    dataTransfer
      .addTransfer(transferParameters)
      .then(() => {
        EventTracker.track({
          event: TrackerEvent.INTERNAL_TRANSFER,
          message: `${
            transferParameters.transferType === TransferType.AMOUNT
              ? transferParameters.amount
              : "Drain"
          } from ${transferParameters.fromAccountId} to ${
            transferParameters.toAccountId
          }`,
        });
        dataCustomerTrackingService.postEvent({
          eventName: "clickedConfirmInternalTransfer",
          domain: FeatureDomain.TRANSFERS,
          subDomain: SubDomain.INTERNAL_TRANSFERS,
          payload: {
            amount:
              selectedTransferType.value === TransferType.AMOUNT && amount
                ? amount.toString()
                : internalTransferContext.state.fromAccount
                ? getAccountAmount(
                    internalTransferContext.state.fromAccount
                  ).toString()
                : "",
            is_drain:
              selectedTransferType.value === TransferType.AMOUNT
                ? "False"
                : "True",
          },
        });
        next(selectedTransferType.value === TransferType.DRAIN);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setIsLoading(false));
  };

  if (
    !localizationContext.state.country ||
    !internalTransferContext.state.fromAccount ||
    !internalTransferContext.state.toAccount
  ) {
    return null;
  }

  if (isLoading) {
    return <Spinner />;
  }

  const showLunarNewYearInformation =
    hasEmergingMarketPositions &&
    selectedTransferType?.value === TransferType.DRAIN &&
    isSavingsAccount(internalTransferContext.state.toAccount);

  return (
    <div className="internal-transfer-amount">
      <h2>
        <TranslatedText id="internal-transfer.story.amount.header" />
      </h2>
      <Form lysaFormRef={formRef} onSubmit={onSubmit}>
        <Card>
          {error && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="internal-transfer.story.amount.error" />
            </Snackbar>
          )}
          <RadioGroup
            header={
              <TranslatedText id="internal-transfer.story.amount.header" />
            }
            alternatives={transferAlternatives}
            onChange={(alternative) => {
              error && setError(false);
              setSelectedTransferType(alternative);
            }}
            value={selectedTransferType}
            validators={[
              new RequiredValidator(
                intl.formatMessage({
                  id: "internal-transfer.story.amount.type-required",
                })
              ),
            ]}
          />
          <AnimateHeight
            isOpen={
              selectedTransferType?.value === TransferType.AMOUNT ||
              showLunarNewYearInformation
            }
          >
            {selectedTransferType?.value === TransferType.AMOUNT && (
              <>
                <Snackbar type={SNACKBAR_TYPES.INFO}>
                  <TranslatedText
                    id="internal-transfer.story.amount.amount-info"
                    values={{
                      minPercentage: intl.formatNumber(
                        getMinRemainingPercentage(
                          internalTransferContext.state.toAccount
                        ) || 0,
                        {
                          style: "percent",
                        }
                      ),
                      maxAmount: intl.formatNumber(
                        getMaxTransferableAmount(
                          internalTransferContext.state.fromAccount,
                          internalTransferContext.state.toAccount
                        ) || 0,
                        {
                          style: "currency",
                          currency: localizationContext.state.currency,
                        }
                      ),
                    }}
                  />
                </Snackbar>
                <MoneyInput
                  currency={localizationContext.state.currency}
                  value={internalTransferContext.state.amount?.toString()}
                  onChange={(value) => {
                    error && setError(false);
                    internalTransferContext.setState({ amount: Number(value) });
                  }}
                  decimalScale={2}
                  validators={[
                    new RequiredValidator(
                      intl.formatMessage({
                        id: "internal-transfer.story.amount.required",
                      })
                    ),
                    new MinValidator(
                      0.01,
                      intl.formatMessage(
                        {
                          id: "internal-transfer.story.amount.min",
                        },
                        {
                          amount: intl.formatNumber(0, {
                            style: "currency",
                            currency: localizationContext.state.currency,
                          }),
                        }
                      )
                    ),
                    new MaxValidator(
                      getMaxTransferableAmount(
                        internalTransferContext.state.fromAccount,
                        internalTransferContext.state.toAccount
                      ) || 0,
                      intl.formatMessage(
                        {
                          id: "internal-transfer.story.amount.max",
                        },
                        {
                          amount: intl.formatNumber(
                            getMaxTransferableAmount(
                              internalTransferContext.state.fromAccount,
                              internalTransferContext.state.toAccount
                            ) || 0,
                            {
                              style: "currency",
                              currency: localizationContext.state.currency,
                            }
                          ),
                        }
                      )
                    ),
                  ]}
                />
              </>
            )}
            {showLunarNewYearInformation ? (
              <EmergingMarketsToSavingsAccountSnackbar />
            ) : null}
          </AnimateHeight>
        </Card>
        <TransferItem
          toAccount={internalTransferContext.state.toAccount}
          fromAccount={internalTransferContext.state.fromAccount}
          amount={
            selectedTransferType?.value === TransferType.DRAIN
              ? getAccountAmount(
                  internalTransferContext.state.fromAccount
                ).toString()
              : internalTransferContext.state.amount?.toString()
          }
        />
        <Button
          type="submit"
          block
          label={<TranslatedText id="internal-transfer.story.amount.button" />}
        />
      </Form>
      {isIskToSavings && <DisclaimerIskToSavings />}
    </div>
  );
}

const getAccountAmount = (account: MergedInternalTransferAccount) => {
  return isMergedInternalTransferInvestmentAccount(account)
    ? account.worth
    : account.totalBalance;
};

const getMinRemainingPercentage = (toAccount: CompoundAccount) => {
  if (isInvestmentAccount(toAccount)) {
    return MIN_TRANSFER_PERCENTAGE_ISK_TO_ISK;
  } else if (isSavingsAccount(toAccount)) {
    return MIN_TRANSFER_PERCENTAGE_ISK_TO_SAVINGSACCOUNT;
  }
};
